const Experiences = [
  {
    id: 1,
    yearOfCompletion: "2019",
    title: "Secretary",
    company: "Neilrob Company Limited",
    description:
      "My experience at Neilrob Company Limited helped me learn a lot of Customer Experience which helps me inter-relate to Customers effectively and with ease. Team work at Neilrob has been a delightful moment",
  },
  {
    id: 2,
    yearOfCompletion: "2021",
    title: "Full Stack Developer",
    company: "Alias Solutions Limited",
    description:
      "With my love for web technology, I worked for Alias Solutions as a full stack developer and developed systems such as USSDs, Fin-Tech Apps, etc. My experience there was interesting and I was glad to learn new techniques pertaining the work.",
  },
  {
    id: 3,
    yearOfCompletion: "2024",
    title: "Senior Frontend Developer",
    company: "ExpatSwap",
    description:
      "Working with Expatswap brings this collaborative effort ensuring splendid results on every project worked on. I worked on the Frontend together with my team to bring this awesome website to being. Constructive criticism, weightful suggestions and the willingness to make changes helped improve every masterpiece we produced.",
  },
  {
    id: 4,
    yearOfCompletion: "2024",
    title: "Full Stack Developer",
    company: "Uncut Lab LLC",
    description:
      "Working with Uncut Lab LLC was a harmonious collaboration that consistently delivered outstanding results on each project we undertook. As a Full-Stack developer, I actively contributed to both the frontend and backend aspects of our innovative solutions. Together with my dedicated team at Uncut Lab LLC, we transformed visions into tangible digital experiences. Embracing constructive feedback, offering insightful suggestions, and maintaining a proactive approach to refining our work were pivotal in elevating the quality of every product we developed.",
  },
];

export default Experiences;
