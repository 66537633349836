import { useEffect, useState } from "react";
// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import { Navbar } from "./components/ui/navbar/Navbar";
import { Home } from "./pages/Home/Home";
import { Spinner } from "./services/spinner/spinner.service";

function App() {
  const [loading, setLoading] = useState(true);

  const navigations = [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "*",
      component: <Navigate to="/" />,
    },
  ];
  // Initialize Firebase
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PRODUCT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  };
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  return (
    <main className="App">
      <Router>
        <Navbar />
        <Routes>
          {navigations.map((nav, index) => (
            <Route key={index} path={nav.path} element={nav.component}></Route>
          ))}
        </Routes>
      </Router>
      <Spinner loading={loading} />
    </main>
  );
}

export default App;
