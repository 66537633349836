import Experiences from "./experiences";
import Portfolio from "./portfolio";
import Skills from "./skills";

const SearchData = [
  {
    title: "Home",
    description: "",
    category: "Home",
    link: "#home",
  },
  {
    title: "Contact",
    description: "",
    category: "Contact",
    link: "#contact",
  },
  {
    title: "About",
    description:
      "Hello, I'm Jason Addy, a Full-Stack Developer with a passion for web development and design. With over six years entrenched in Web and Software Development, I've cultivated invaluable expertise, crafting seamless digital experiences and navigating intricate web technologies. Beyond coding, my passions encompass gaming, where I relish immersive narratives, and music, where I not only listen but also create. Additionally, adventure stories captivate me, offering inspiration drawn from heroic tales and audacious escapades. My life's mantra centers on pursuing dreams with unwavering dedication, aiming for purpose and fulfillment in all endeavors. Looking forward, I harbor ambitions of founding my own web development venture, channeling my skills to innovate and deliver user-centric solutions that resonate on a global scale.",
    category: "About",
    link: "#about",
  },
];

Portfolio.forEach((item) => {
  SearchData.push({
    title: item.title,
    description: item.description,
    category: "Portfolio",
    link: "#portfolio",
  });
});

Experiences.forEach((item) => {
  SearchData.push({
    title: item.title,
    description: item.description,
    category: "Experiences",
    link: "#experience",
  });
});

Skills.forEach((item) => {
  SearchData.push({
    title: item.name,
    description: "",
    category: "Skills",
    link: "#skills",
  });
});

export default SearchData;
