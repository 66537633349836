import React from "react";
import Logo from "../../assets/images/logo.png";
import "./spinner.service.css";

export const Spinner = ({ loading }) => {
  return (
    <div className={`spinner ${!loading ? "hidden" : ""}`}>
      <img src={Logo} alt="logo" width={70} height={70} />
    </div>
  );
};
