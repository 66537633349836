import emailjs from "@emailjs/browser";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Typewriter from "typewriter-effect/dist/core";
import Resume from "../../assets/documents/Jason+Addy+CV+Update_compressed.pdf";
import Jason from "../../assets/images/jason.png";
import Experiences from "../../data/experiences";
import Portfolio from "../../data/portfolio";
import Skills from "../../data/skills";

import { ToastContainer, toast } from "react-toastify";
import { Footer } from "../../components/ui/footer/Footer";
import "./Home.css";

export const Home = () => {
  const socialLinks = [
    {
      name: "Github",
      url: "https://github.com/Button-20?tab=repositories",
      icon: "bi bi-github",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/jason-addy-972667137/",
      icon: "bi bi-linkedin",
    },
    {
      name: "WhatsApp",
      url: "https://wa.me/233550653404",
      icon: "bi bi-whatsapp",
    },
    {
      name: "Email",
      url: "mailto:jasonaddy51@gmail.com",
      icon: "bi bi-envelope",
    },
  ];

  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    receipient: "Jason Addy",
    subject: "",
    message: "",
  });

  const [contactFormErrors, setContactFormErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [portfolioSlice, setPortfolioSlice] = useState(6);

  const [loading, setLoading] = useState(false);

  const startTyping = () => {
    // create typewriter effect for name and add a moving cursor
    const name = document.getElementById("name");
    const cursor = document.createElement("span");
    cursor.classList.add("cursor");
    name.appendChild(cursor);
    const typewriter = new Typewriter(name, {
      delay: 75,
      cursor: "",
      deleteSpeed: 25,
      loop: true,
    });
    typewriter
      .typeString("Jason Addy")
      .pauseFor(2000)
      .deleteAll()
      .typeString("a Full-Stack Developer")
      .pauseFor(2000)
      .deleteAll()
      .typeString("a DevOps Engineer")
      .pauseFor(2000)
      .deleteAll()
      .typeString("a Graphic Designer")
      .pauseFor(2000)
      .deleteAll()
      .typeString("a Game Developer")
      .pauseFor(2000)
      .deleteAll()
      .typeString("a Music Producer")
      .pauseFor(2000)
      .deleteAll()
      .typeString("a Gamer")
      .pauseFor(2000)
      .deleteAll()
      .start();
  };

  const handleContactFormChange = (e) => {
    if (e.target.name === "name") {
      if (e.target.value.length < 3) {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "Name must be at least 3 characters long",
        });
      } else {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "",
        });
      }
    }

    if (e.target.name === "email") {
      let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!e.target.value.includes("@") || !emailRegex.test(e.target.value)) {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "Email must be a valid email address",
        });
      } else {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "",
        });
      }
    }

    if (e.target.name === "subject") {
      if (e.target.value.length < 5) {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "Subject must be at least 5 characters long",
        });
      } else {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "",
        });
      }
    }

    if (e.target.name === "message") {
      if (e.target.value.length < 10) {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "Message must be at least 10 characters long",
        });
      } else {
        setContactFormErrors({
          ...contactFormErrors,
          [e.target.name]: "",
        });
      }
    }

    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      contactForm,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );
    if (resp.status === 200) {
      toast.success("Message sent successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        theme: "colored",
      });
      setContactForm({
        name: "",
        email: "",
        receipient: "Jason Addy",
        subject: "",
        message: "",
      });
    } else {
      toast.error("Message failed to send", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        theme: "colored",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    startTyping();
  }, []);

  return (
    <main>
      <div className="hero-section" id="home">
        <div className="hero-text">
          <h3 className="fs-36">
            Hello, I'm{" "}
            <span className="fs-60" id="name" style={{ lineHeight: "1rem" }}>
              Jason Addy
            </span>
          </h3>
          <p className="fs-20">
            I'm a full-stack developer with a passion for <br></br> creating
            beautiful websites and applications.
          </p>
          <div className="hero-buttons">
            <a className="btn btn-primary" href={Resume} download={true}>
              Download Resume
            </a>
            <a className="btn btn-primary-outline" href="#contact">
              Contact Me
            </a>
          </div>
        </div>
        <div className="hero-image">
          <img src={Jason} alt="Jason Addy" />
          <ul className="social-links">
            {socialLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  <i className={link.icon}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="about-section" id="about">
        <h3 className="fs-40">About</h3>
        <p className="fs-20">
          Hello, I'm Jason Addy, a Full-Stack Developer who loves making
          websites and apps. I've been working in this field for over six years,
          learning a lot about building and designing digital stuff. Apart from
          my job, I really enjoy playing video games and making music. I also
          love reading exciting adventure stories that inspire me. My main goal
          in life is{" "}
          <span>
            to chase my dreams with determination and find happiness in what I
            do.
          </span>{" "}
          In the future, I hope to start my own web development business, where
          I can use my skills to create cool and useful websites for people all
          over the world.
        </p>

        <div className="skills" id="skills">
          <h3 className="fs-40">My Skills</h3>
          <div className="skills-container">
            {Skills.map((skill, index) => (
              <div key={index} className="skill">
                <img src={skill.icon} alt={skill.name} />
                <span className="fs-20">{skill.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="portfolio-section" id="portfolio">
          <h3 className="fs-40">Portfolio</h3>
          <div className="portfolio-container">
            {Portfolio.slice(0, portfolioSlice).map((item, index) => (
              <div className="portfolio" key={index}>
                <img src={item.image} alt="Portfolio" />
                <div className="portfolio-text">
                  <p className="fs-28">{item.title}</p>
                  <small className="fs-12">
                    {item.description.substring(0, 100)}...
                  </small>
                  <button
                    className="btn btn-primary fs-10"
                    onClick={() => window.open(item.link, "_blank")}
                  >
                    See More <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
          {
            // show view more button if portfolio slice is less than portfolio length
            portfolioSlice < Portfolio.length && (
              <center>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setPortfolioSlice(portfolioSlice + 6);
                  }}
                >
                  View More
                </button>
              </center>
            )
          }
        </div>
      </div>
      <div className="experience-section" id="experience">
        <h3 className="fs-40">Experience</h3>
        <div className="experience-container">
          {Experiences.map((experience, index) => (
            <div className="experience" key={index}>
              <p className="fs-20">{experience.yearOfCompletion}</p>
              <div className="experience-text">
                <p className="fs-28">{experience.title}</p>
                <p className="fs-20">{experience.company}</p>
                <p className="fs-16">{experience.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-section" id="contact">
        <h3 className="fs-40">Contact Me</h3>
        <center>
          <p className="fs-20">
            Feel free to contact me if you have any questions or would like to
            get in touch.
          </p>
          <div className="social-links">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noreferrer"
                className="social-link"
              >
                <i className={link.icon}></i>
              </a>
            ))}
          </div>
        </center>
        <form className="contact-form" onSubmit={handleContactFormSubmit}>
          <div className="form-group">
            <label className="fs-18">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={contactForm.name}
              name="name"
              onChange={handleContactFormChange}
              required
            />
            {contactFormErrors.name && (
              <small className="text-danger fs-12">
                {contactFormErrors.name}
              </small>
            )}
          </div>
          <div className="form-group">
            <label className="fs-18">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={contactForm.email}
              name="email"
              onChange={handleContactFormChange}
              required
            />
            {contactFormErrors.email && (
              <small className="text-danger fs-12">
                {contactFormErrors.email}
              </small>
            )}
          </div>
          <div className="form-group">
            <label className="fs-18">Subject</label>
            <input
              type="text"
              className="form-control"
              value={contactForm.subject}
              placeholder="Subject"
              name="subject"
              onChange={handleContactFormChange}
              required
            />
            {contactFormErrors.subject && (
              <small className="text-danger fs-12">
                {contactFormErrors.subject}
              </small>
            )}
          </div>
          <div className="form-group">
            <label className="fs-18">Message</label>
            <textarea
              className="form-control"
              placeholder="Message"
              name="message"
              value={contactForm.message}
              onChange={handleContactFormChange}
              required
            ></textarea>
            {contactFormErrors.message && (
              <small className="text-danger fs-12">
                {contactFormErrors.message}
              </small>
            )}
          </div>
          <center>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={
                contactFormErrors.name ||
                contactFormErrors.email ||
                contactFormErrors.subject ||
                contactFormErrors.message ||
                loading
              }
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              {!loading && "Send Message"}
            </button>
          </center>
        </form>
      </div>
      <Footer />
      <ToastContainer />
    </main>
  );
};
