import React from "react";
import "./Footer.css";

export const Footer = () => {
  return (
    <footer className="footer-section">
      <center>
        <p className="fs-12">
          Designed and Developed by Jason Addy &copy; 2024
        </p>
      </center>
    </footer>
  );
};
