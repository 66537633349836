
const Skills = [
  {
    name: "Python",
    icon: "https://img.icons8.com/fluency/480/python.png",
  },
  {
    name: "JavaScript",
    icon: "https://img.icons8.com/color/480/000000/javascript.png",
  },
  {
    name: "Ionic",
    icon: "https://img.icons8.com/color/480/000000/ionic.png",
  },
  {
    name: "React Native",
    icon: "https://img.icons8.com/color/480/000000/react-native.png",
  },
  {
    name: "React",
    icon: "https://img.icons8.com/color/480/000000/react-native.png",
  },
  {
    name: "Node.js",
    icon: "https://img.icons8.com/color/480/000000/nodejs.png",
  },
  {
    name: "MongoDB",
    icon: "https://img.icons8.com/color/480/000000/mongodb.png",
  },
  {
    name: "MySQL",
    icon: "https://img.icons8.com/color/480/000000/mysql-logo.png",
  },
  {
    name: "HTML",
    icon: "https://img.icons8.com/color/480/000000/html-5.png",
  },
  {
    name: "CSS",
    icon: "https://img.icons8.com/color/480/000000/css3.png",
  },
  {
    name: "Bootstrap",
    icon: "https://img.icons8.com/color/480/000000/bootstrap.png",
  },
  {
    name: "Sass",
    icon: "https://img.icons8.com/color/480/000000/sass.png",
  },
  {
    name: "Git",
    icon: "https://img.icons8.com/color/480/000000/git.png",
  },
  {
    name: "GitHub",
    icon: "https://img.icons8.com/ios-glyphs/480/000000/github.png",
  },
  {
    name: "VS Code",
    icon: "https://img.icons8.com/color/480/000000/visual-studio-code-2019.png",
  },
  {
    name: "Unity",
    icon: "https://img.icons8.com/ios-filled/480/000000/unity.png",
  },
  {
    name: "FL Studio",
    icon: "https://img.icons8.com/color/480/000000/fl-studio.png",
  },
  {
    name: "Adobe Photoshop",
    icon: "https://img.icons8.com/color/480/000000/adobe-photoshop.png",
  },
  {
    name: "Adobe Illustrator",
    icon: "https://img.icons8.com/color/480/000000/adobe-illustrator.png",
  },
  {
    name: "Angular",
    icon: "https://img.icons8.com/color/480/angularjs.png",
  },
  {
    name: "TypeScript",
    icon: "https://img.icons8.com/color/480/000000/typescript.png",
  },
  {
    name: "C#",
    icon: "https://img.icons8.com/color/480/000000/c-sharp-logo.png",
  },
  {
    name: "C++",
    icon: "https://img.icons8.com/color/480/000000/c-plus-plus-logo.png",
  },
  {
    name: "Redis",
    icon: "https://img.icons8.com/color/480/000000/redis.png",
  },
  {
    name: "Java",
    icon: "https://img.icons8.com/color/480/000000/java-coffee-cup-logo.png",
  },
  {
    name: "PHP",
    icon: "https://img.icons8.com/external-those-icons-flat-those-icons/96/external-PHP-programming-and-development-those-icons-flat-those-icons.png",
  },
  {
    name: "WordPress",
    icon: "https://img.icons8.com/color/480/000000/wordpress.png",
  },
  {
    name: "Joomla",
    icon: "https://img.icons8.com/color/480/000000/joomla.png",
  },
  {
    name: "Shopify",
    icon: "https://img.icons8.com/color/480/000000/shopify.png",
  },
  {
    name: "Django",
    icon: "https://img.icons8.com/color/480/000000/django.png",
  },
  {
    name: "Express",
    icon: "https://img.icons8.com/color/480/000000/express.png",
  },
  {
    name: "jQuery",
    icon: "https://img.icons8.com/ios-filled/480/000000/jquery.png",
  },
  {
    name: "Android",
    icon: "https://img.icons8.com/color/480/000000/android-os.png",
  },
  {
    name: "iOS",
    icon: "https://img.icons8.com/color/480/000000/ios-logo.png",
  },
  {
    name: "AWS",
    icon: "https://img.icons8.com/color/480/000000/amazon-web-services.png",
  },
];

export default Skills;